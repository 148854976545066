html, body {
    height: 100% !important;
    margin: 0 auto !important;
}

.app-user-list {
    margin: auto;
}

.ag-header-cell-text {
    margin: auto;
}

.aaa {
    margin: auto;
}

.h5 {
    color: black;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #2c2c2c;
    font-size: 1.14rem;
    margin: auto;
}
.align-text{
    text-align: left;
}
.titleCourse{
    font-size: 1.4rem !important;
}

.h5:hover {
    color: black !important;
}

.h5 {
    color: black;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #2c2c2c;
    font-size: 1.14rem;
    margin: auto;
}

.h5:hover {
    color: black !important;
}

.h11 {
    color: #2c2c2c;
}

.h11:hover {
    color: #2c2c2c;
}

.margin {
    margin: auto;
}

.padding {
    padding: 0% !important;
}

.ag-root-wrapper.ag-layout-normal {
    height: 133% !important;
}

[dir]
.ag-theme-material
.ag-ltr
.ag-has-foucs
.ag-cell-focus:not(.ag-cell-range-selected) {
    border: 1px solid white !important;
}

.user-name {
    margin-top: 7px !important;
}

.panel-group {
    margin-top: 30px;
}

.panel-collapse {
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.panel-close {
    max-height: 0;
}

#myId,
#myId2 {
    margin-left: 0px;
    max-width: 100% !important;
    max-height: 350px !important;
    margin: auto !important;
    border-radius: 12px;
}

#myId1 {
    margin-left: 0px;
    width: 80% !important;
    height: 80% !important;
    margin: auto !important;
    display: block !important;
    margin-bottom: 35px !important;
}
#myId5 {
    margin-left: 0px;
    width: 100% !important;
    height: 100% !important;
    margin: auto !important;
    display: block !important;
    margin-bottom: 50px !important;
}

.video-js.vjs-peertube-skin .vjs-control-bar, .video-js.vjs-peertube-skin .vjs-big-play-button, .video-js.vjs-peertube-skin .vjs-settings-dialog {
    background-color: #f6a018 !important;
}
/* iframe {
  width: 100%;
  height: 550px;
} */
#myId3 {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
    background-color: white;
}

/*iframe {*/
/*    pointer-events: none;*/
/*}*/
/*.toolbar{*/
/*    display: none;*/
/*}*/


/*.yt-cover{*/
/*    position: absolute !important;*/
/*    top: 0 !important;*/
/*    bottom:0 !important;*/
/*    right:0 !important;*/
/*    left:0 !important;*/
/*    z-index:1000;*/
/*}*/

.flatpickr-calendar.open {
    display: inline-block !important;
    z-index: 9999999999 !important;
}

.ant-menu-submenu-selected {
    color: #f6a018 !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #f6a018 !important;
}

.ant-menu-item-selected {
    color: #f6a018 !important;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
    color: #f6a018 !important;
}

/* [dir=ltr] .ant-menu-submenu-inline .ant-menu-submenu-arrow::before:hover {

            color: #f6a018 !important;

} */
.ant-menu-submenu:hover
> .ant-menu-submenu-title
> .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #f6a118 !important;
}

[dir] .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fffaef !important;
}

[dir] .ant-menu-item .ant-menu-item-icon + span,
[dir] .ant-menu-submenu-title .ant-menu-item-icon + span,
[dir] .ant-menu-item .anticon + span,
[dir] .ant-menu-submenu-title .anticon + span {
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.1s !important;
}

.chartshead {
    margin: auto !important;
}


.picnot {
    max-width: 100%;
    max-height: 400px;
}

.not_found {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.09px;
    text-align: center;
    color: #33333a;
    font-size: 33px;
}

.spanspin {
    color: #f2871e;
}

.explore_notFound {
    height: 2.625rem;

    font-size: 0.83rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #908e8e;
}

.btn_notFound {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 0.813rem 1.938rem 0.75rem !important;
    border-radius: 12px !important;
    background-color: #f2871e !important;
    border-color: #f2871e !important;
    text-decoration: none !important;
}

.example {
    margin-bottom: 20px;
    margin-top: 20%;

    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    min-height: 270px;
}

.ant-spin-dot-item {
    background-color: #f2871e !important;
}

.btn_notFound:hover {
    color: #ffffff !important;
}

.notfoundbtn {
    font-size: 18px !important;
    padding: 10px 15px !important;
}


.navigation .navigation-header {
    font-family: 'Nunito', 'Almarai', sans-serif !important;
}

.sessiontittle {
    cursor: pointer;
    text-align: left;
}

/*  ----- vlc css ------ */

.vlcbanner {
    min-height: 272px;
    margin: 26px 0px;
    padding: 20px 43.5px 9px 75px;
    border-radius: 6px;
    background-color: #ffe9d4;
}

.vlcRoombanner {
    min-height: 272px;
    margin: 26px 0px;
    padding: 20px 43.5px 9px 75px;
}

.vlcbannerh1 {
    font-size: 35px;
    font-weight: 600;
    text-align: left;
    color: #fbbe82;


}

.vlcbannertxt {
    font-size: 21px;
    font-weight: 500;
    text-align: left;
    color: #3a3a3a;
}

.vlcbannerimg {
    max-width: 100%;
}

@media only screen and (max-width: 600px) {

    .vlcbanner {
        min-height: 272px;
        margin: 0px !important;
        padding: 20px !important;
        border-radius: 6px;
        background-color: #ffe9d4;
    }

    .vlcRoombanner {
        min-height: 272px;
        margin: 0px !important;
        padding: 20px !important;
    }

    .vlcbannerh1 {
        font-size: 30px;
        font-weight: 600;
        text-align: left;
        color: #fbbe82;


    }

    .vlcbannertxt {
        font-size: 17px;
        font-weight: 500;
        text-align: left;
        color: #3a3a3a;
    }

}

[dir] .progress {
    height: 20px;
}

[dir] .progress-bar {
    background-color: #f2871e !important;
}

.room-number-red {
    color: #db2e2e;
}

.room-number-green {
    color: #0b9a23;
}

.ul-navs {
    background-color: #ffffff;
    border: #d1d1d1 1px solid;
    padding: 15px;
    border-radius: 12px;
}

.Meeting-Options {
    font-size: 24px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #5d5d5d;
}

.li-nav {
    font-size: 15px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    text-align: center;
}

/* .nvi{
    padding: 14px 13px 13px 31px;
    border-radius: 8px;
    border: solid 0.5px #ea8729;
} */
/*.nav.nav-tabs .nav-item .nav-link.active {*/
/*    position: relative;*/
/*    color: #ff9f43;*/
/*    border: solid 0.5px #ea8729 !important;*/
/*    transition: all 0.2s ease;*/
/*}*/

[dir] .nav.nav-tabs .nav-item .nav-link.noBackground {
    background: none !important;
}

[dir] .nav.nav-tabs {
    box-shadow: none !important;
}

.bboORw {
    border-radius: 6px !important;
    background-color: #ffead6 !important;
}

.jkEUmm {
    font-size: 15px !important;
}

#cell-JehN0i4qLv-undefined {
    display: block !important;
}

.form-control:focus ~ .form-control-position svg {
    stroke: #ea8729 !important;
}

[dir=ltr] .pricing-card .annual-plan .plan-price sup {
    left: 0.2rem !important;
}

.pricing-card .annual-plan .plan-price sup {
    top: -1.5rem !important;
}

[dir=ltr] .mr-25, [dir=ltr] .mx-25 {
    margin-right: 0.25rem !important;
}

.font-medium-1 {
    font-size: 1.1rem !important;
}

.font2 {
    font-size: 0.8rem !important;
}

.pricing-card .annual-plan .plan-price span {
    font-size: 3.5rem !important;
    line-height: 0.8 !important;
}

.font-weight-bolder {
    font-weight: 600 !important;
    font-size: 55px !important;
}

.font-weight-bolder2 {
    font-weight: 600 !important;
    font-size: 18px !important;
}

[dir] .pricing-card .annual-plan {
    margin-bottom: 1.7rem !important;
}

sup {
    top: -1.5em !important;
}


[dir] .list-group.list-group-circle {
    border: none;
}

.pricing-card .list-group-circle {
    font-weight: 500;
    color: #5e5873;
}

.list-group {
    display: flex;
    flex-direction: column;
}

[dir] .list-group {
    margin-bottom: 0;
    border-radius: 0.358rem;
}

[dir] .list-group {
    padding-left: 0;
}

[dir] .list-group.list-group-circle .list-group-item {
    padding-left: 1.5rem;
}

[dir] .list-group.list-group-circle .list-group-item {
    padding-left: 1.5rem;
}

.list-group.list-group-circle .list-group-item {
    position: relative;
}

[dir] .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

[dir] .list-group-item {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(34, 41, 47, 0.125);
}

.list-group-item {
    position: relative;
    display: block;
    border: none !important;
}

[dir=ltr] .list-group.list-group-circle .list-group-item:after {
    left: 0 !important;
}

[dir=rtr] .list-group.list-group-circle .list-group-item:after {
    right: 0 !important;
}

[dir] .list-group.list-group-circle .list-group-item:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
}

.list-group.list-group-circle .list-group-item:after {
    content: ' ';
    color: #6e6b7b;
    position: absolute;
    height: 10px;
    width: 10px;
    top: 1.15rem;
}

[dir] .list-group .list-group-item:hover {
    background: none !important;
}

.pro {
    border: solid 2px !important;
}

.freetxt {
    font-size: 33px;
    font-weight: 700;
    color: #f2871e;
    padding: 10px;
    margin-bottom: 20px;

}

.pricingimg {
    max-width: 80%;
    max-height: 150px;
}

.modal .modal-header .close span {
    font-weight: 400;
    font-size: 2rem;
    color: #f2871e !important;
}

.spancardV {
    color: #f2871e;
    font-weight: 700;
}

[dir] .react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.1) !important;
}

/*[dir=ltr] .react-confirm-alert-overlay {*/

/*    bottom: 580px !important;*/
/*}*/

.react-confirm-alert-button-group {

    justify-content: center !important;
}

.alertH1 {
    font-size: 20px !important;
    text-align: center !important;
}

.delete {
    background-color: red !important;
}

.no {
    background-color: #ff8510 !important;
}

.alertSize {
    font-size: 1rem !important;
}

.judJDM {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
}

.css-2b097c-container {
    z-index: 9999 !important;
}

/*.css-2b097c-container{*/
/*    z-index: 9999999999 !important;*/

/*}*/
.css-yk16xz-control:hover {
    border-color: #ff9f43 !important;

}

.css-yk16xz-control:focus {
    border-color: #ff9f43 !important;

}

.error {
    color: red !important;
    font-size: 14px !important;
}

.err {
    color: #f2871e !important;
    font-size: 14px !important;

}

#overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(93, 93, 93, 0.7); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

#text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.txtContacts {
    color: white !important;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
}

.cardPrice:hover {
    box-shadow: 0px 10px 20px 10px rgba(255, 166, 108, 0.9) !important;
    margin-top: 20px !important;
}

.pro {
    border: solid 2px #f2871e !important;
}

.runningstyle {
    font-size: 15px;
}

/*Layer*/
.Layerscreen {
    max-height: 100% !important;
}

.w-100 {
    height: 100% !important;
}

.Layerhead {
    font-size: 65px;
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 30px;
}

.layerLogo {
    max-width: 100%;
    max-height: 100%;
}

.layerBtn {
    font-size: 18px !important;
    padding: 12px 40px !important;
}

.layerspan {
    color: #ea8729;
    font-weight: bold !important;
}

.layertxt {
    font-size: 25px;
    padding-top: 20px;
}

.layerlayout {
    padding-left: 20%;
    padding-right: 20%;
}

.layercard {
    padding: 15px !important;
}

.layerImg {
    max-height: 450px !important;
}

.layerImg1 {
    max-height: 550px !important;
}

@media only screen and (max-width: 1024px) {
    .Layerhead {
        font-size: 40px;
        font-weight: bold;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .layerBtn {
        font-size: 18px !important;
        padding: 10px 20px !important;
    }

    .layerLogo {
        max-width: 100%;
        max-height: 70%;
    }

    .layertxt {
        font-size: 20px;
        font-weight: bold !important;
        padding-top: 20px;
    }

    .layerlayout {
        padding-left: 5%;
        padding-right: 5%;
    }

    .layerImg {
        max-width: 90%;
        max-height: 70% !important;
    }

    .layerImg1 {
        max-width: 97%;
        max-height: 70% !important;
    }
}

@media only screen and (min-width: 768px) {
    .headexpire {
        font-size: 35px;
        font-weight: bold;
    }

    .imgexpire {
        max-width: 45%;
        height: auto;
    }
}

@media only screen and (max-width: 767px) {
    .headexpire {
        font-size: 28px;
        font-weight: bold;
    }

    .imgexpire {
        max-width: 100%;
        height: auto;
    }
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background: rgb(255 159 67 / 40%) !important;
    border-color: rgb(255 159 67 / 40%) !important;
    box-shadow: none;
}

.bundle-container {
    display: block;
    position: relative;
}

.bundle-badge {
    background-color: #f39921;
    border-radius: 3px;
    padding: 2px 4px;
    color: white;
    font-size: 13px;
    position: absolute;
    bottom: 10%;
    right: 0;
    box-shadow: -5px 4px 4px -3px #aaaaaa;
}

.renew-bundle-container {
    display: block;
    cursor: pointer;
}

.renew-bundle {
    filter: blur(2px);
    /*background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .3));*/

}

.renew-btn {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
}
@media (max-width: 767px) {
    audio{width: 100%}
}

